import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Caption wrap=flex",
        "componentName": "Caption",
        "wrap": "flex"
      }}>{`<Caption>Picture taken by a cameraman</Caption>
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="Caption" mdxType="Props" />
    <h2 {...{
      "id": "color",
      "style": {
        "position": "relative"
      }
    }}>{`Color`}</h2>
    <p><inlineCode parentName="p">{`default: colorBlackBase`}</inlineCode></p>
    <p>{`You can change the caption's color by passing in a defined color name.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Caption color="primaryDark">
  A title or brief explanation accompanying an illustration, cartoon, or poster
</Caption>
<Caption color="green70">
  A title or brief explanation accompanying an illustration, cartoon, or poster
</Caption>
`}</code></pre>
    <h2 {...{
      "id": "decoration",
      "style": {
        "position": "relative"
      }
    }}>{`Decoration`}</h2>
    <p><inlineCode parentName="p">{`default: none`}</inlineCode></p>
    <p>{`Controls the text-decoration css attribute.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Caption decoration="none">
  A title or brief explanation accompanying an illustration, cartoon, or poster
</Caption>
<Caption decoration="underline">
  A title or brief explanation accompanying an illustration, cartoon, or poster
</Caption>
<Caption decoration="line-through">
  A title or brief explanation accompanying an illustration, cartoon, or poster
</Caption>
`}</code></pre>
    <h2 {...{
      "id": "fontfamily",
      "style": {
        "position": "relative"
      }
    }}>{`FontFamily`}</h2>
    <p><inlineCode parentName="p">{`default: system`}</inlineCode></p>
    <p>{`Font-families can be used to discern between multiple uses of a Caption.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Caption fontFamily="primary">
  A title or brief explanation accompanying an illustration, cartoon, or poster
</Caption>
<Caption fontFamily="secondary">
  A title or brief explanation accompanying an illustration, cartoon, or poster
</Caption>
<Caption fontFamily="system">
  A title or brief explanation accompanying an illustration, cartoon, or poster
</Caption>
`}</code></pre>
    <h2 {...{
      "id": "fontweight",
      "style": {
        "position": "relative"
      }
    }}>{`FontWeight`}</h2>
    <p><inlineCode parentName="p">{`default: regular`}</inlineCode></p>
    <p>{`Controls the font-weight css attribute. Only 3 values are available. If a value
is missing, it will fallback on the more bold weight.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Caption fontWeight="inherit">
  A title or brief explanation accompanying an illustration, cartoon, or poster
</Caption>
<Caption fontWeight="regular">
  A title or brief explanation accompanying an illustration, cartoon, or poster
</Caption>
<Caption fontWeight="medium">
  A title or brief explanation accompanying an illustration, cartoon, or poster
</Caption>
<Caption fontWeight="bold">
  A title or brief explanation accompanying an illustration, cartoon, or poster
</Caption>
`}</code></pre>
    <h2 {...{
      "id": "level",
      "style": {
        "position": "relative"
      }
    }}>{`Level`}</h2>
    <p><inlineCode parentName="p">{`default: 1`}</inlineCode></p>
    <p>{`Captions of lower importance can be displayed by altering the `}<inlineCode parentName="p">{`level`}</inlineCode>{` prop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Caption level={1}>
  A title or brief explanation accompanying an illustration, cartoon, or poster
</Caption>
<Caption level={2}>
  A title or brief explanation accompanying an illustration, cartoon, or poster
</Caption>
`}</code></pre>
    <h2 {...{
      "id": "textalign",
      "style": {
        "position": "relative"
      }
    }}>{`TextAlign`}</h2>
    <p><inlineCode parentName="p">{`default: inherit`}</inlineCode></p>
    <p>{`Controls the css text-align attribute.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Caption textAlign="inherit">
  Inherit.
</Caption>
<Caption textAlign="left">
  Left.
</Caption>
<Caption textAlign="right">
  Right.
</Caption>
<Caption textAlign="center">
  Center.
</Caption>
<Caption textAlign="justify">
  Justify Justify Justify Justify Justify Justify Justify Justify Justify Justify Justify Justify Justify Justify.
</Caption>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      